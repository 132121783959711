import React from 'react';
import SectionContainer from '../components/section/sectionContainer';
import MaxWidthContainer from '../components/core/maxWidthContainer';
import Layout from '../components/core/layout';
import HardwareForm from '../components/hardware/hardwareForm';
import { graphql } from 'gatsby';
import { HardwareMetadata } from '../lib/sanity-types/hardwareMetadata';
import { createFooterProps, mapEdgesToNodes } from '../lib/helpers';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { formatPrice } from './../lib/formatPrice';

export const query = graphql`
  query HardwareDetailTemplateQuery($shopifyId: String!, $handle: String!) {
    shopifyProduct: shopifyProduct(shopifyId: { eq: $shopifyId }) {
      ...ShopifyProductFields
    }

    hardwareMetadata: sanityHardware(slug: { current: { eq: $handle } }) {
      id
      title
      slug {
        _key
        _type
        current
      }
      hashrate
      powerConsumption
      algorithm
      shipping
      condition
      minimumOrder
      seo {
        title
        description
        keywords
        ogImage {
          asset {
            altText
            gatsbyImageData(fit: FILLMAX, placeholder: BLURRED)
          }
        }
      }
    }

    siteConfig: allSanitySiteConfiguration {
      edges {
        node {
          id
          title
          mainImage {
            ...SanityImage
            altText
          }
          mainNavigation {
            id
            url
            navigationMenuName
          }
          footerTagline
          footerSocialMedia {
            id
            link
            name
            icon
          }
          footerProductLinks {
            id
            navigationMenuName
            url
          }
          footerGeneralLinks {
            id
            navigationMenuName
            url
          }
          footerCompanyLinks {
            id
            navigationMenuName
            url
          }
        }
      }
    }
  }
`;

const HardwareDetail = (props: any) => {
  const { shopifyProduct } = props.data;
  const hardwareMetadata: HardwareMetadata =
    props.data && (props.data.hardwareMetadata as HardwareMetadata);
  const siteConfig =
    props.data && props.data.siteConfig && mapEdgesToNodes(props.data.siteConfig)[0];
  const footerProps = createFooterProps(siteConfig);
  const productImage = getImage(shopifyProduct.images[0].localFile);
  const productImageAltText = shopifyProduct?.images[0]?.altText || '';
  const productPrice = shopifyProduct.priceRangeV2.minVariantPrice.amount;
  const currency = shopifyProduct.priceRangeV2.minVariantPrice.currencyCode;

  return (
    <Layout
      seoMetadata={hardwareMetadata?.seo}
      navigationItems={siteConfig.mainNavigation}
      mainImage={siteConfig.mainImage}
      footerProps={footerProps}>
      <MaxWidthContainer>
        <SectionContainer>
          <div className='mt-12 lg:mt-20 mb-12 lg:mb-20'>
            <div className='w-full flex flex-col lg:flex-row'>
              <div className='w-full lg:w-5/12 overflow-hidden'>
                <div className='w-full h-full bg-white rounded-lg overflow-hidden'>
                  {productImage && <GatsbyImage image={productImage} alt={productImageAltText} />}
                </div>
              </div>

              <div className='w-full lg:w-7/12 mt-5 lg:mt-0 lg:ml-6'>
                <div className='px-8 py-10 w-full h-full bg-white rounded-lg'>
                  <h1 className='text-titleSmallMobile sm:text-titleSmallTablet lg:text-titleSmall font-universBoldCondensed mb-3 leading-tight'>
                    {hardwareMetadata?.title}
                  </h1>
                  <div className='mb-6'>
                    <p className='text-xl'>{formatPrice(currency, productPrice)}</p>
                  </div>
                  <div className='mb-6'>
                    <p className='leading-loose text-base'>{shopifyProduct?.description}</p>
                  </div>
                  <div className='mb-12'>
                    <HardwareForm
                      product={shopifyProduct}
                      hardwareMetadata={hardwareMetadata}></HardwareForm>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SectionContainer>
      </MaxWidthContainer>
    </Layout>
  );
};

export default HardwareDetail;
