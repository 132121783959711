import React from 'react';
import FaIcon from '../core/faIcon';

type HardwareDetailPropertiesProps = {
  hashrate: string;
  shipping: string;
  powerConsumption: string;
  condition: string;
  algorithm: string;
  minimumOrder: string;
};

const HardwareDetailProperties = (props: HardwareDetailPropertiesProps) => {
  return (
    <div className='w-full flex flex-wrap mt-8 md:text-base'>
      <div className='w-full xl:w-1/2 lg:min-w-170px flex flex-row items-center justify-start mt-2'>
        <div className='w-8'>
          <FaIcon color={'text-blue-aqua'} iconName={'fas fa-percentage'} size={'fa-lg'}></FaIcon>
        </div>
        <div className='w-auto flex flex-row items-end'>
          <p className='mr-1 leading-none'>Hashrate:</p>
          <p className='font-universBoldCondensed leading-none'>{props.hashrate}</p>
        </div>
      </div>
      <div className='w-full xl:w-1/2 lg:min-w-170px flex flex-row items-center justify-start mt-2'>
        <div className='w-8'>
          <FaIcon color={'text-blue-aqua'} iconName={'far fa-clock'} size={'fa-lg'}></FaIcon>
        </div>
        <div className='w-auto flex flex-row items-end'>
          <p className='mr-1 leading-none'>Versand:</p>
          <p className='font-universBoldCondensed leading-none'>{props.shipping}</p>
        </div>
      </div>
      <div className='w-full flex flex-wrap mt-6'>
        <div className='w-full xl:w-1/2 lg:min-w-170px flex flex-row items-center justify-start mt-2'>
          <div className='w-8'>
            <FaIcon color={'text-blue-aqua'} iconName={'fas fa-bolt'} size={'fa-lg'}></FaIcon>
          </div>
          <div className='w-auto flex flex-row items-end'>
            <p className='mr-1 leading-none'>Stromverbrauch:</p>
            <p className='font-universBoldCondensed leading-none'>{props.powerConsumption}</p>
          </div>
        </div>
        <div className='w-full xl:w-1/2 lg:min-w-170px flex flex-row items-center justify-start mt-2'>
          <div className='w-8'>
            <FaIcon color={'text-blue-aqua'} iconName={'fas fa-check'} size={'fa-lg'}></FaIcon>
          </div>
          <div className='w-auto flex flex-row items-end py-1'>
            <p className='mr-1 leading-none'>Zustand:</p>
            <p className='font-universBoldCondensed leading-none'>{props.condition}</p>
          </div>
        </div>
      </div>
      <div className='w-full flex flex-wrap mt-6'>
        <div className='w-full xl:w-1/2 lg:min-w-170px flex flex-row items-center justify-start mt-2'>
          <div className='w-8'>
            <FaIcon color={'text-blue-aqua'} iconName={'fas fa-microchip'} size={'fa-lg'}></FaIcon>
          </div>
          <div className='w-auto flex flex-row items-end py-1'>
            <p className='mr-1 leading-none'>Algorithmus:</p>
            <p className='font-universBoldCondensed leading-none'>{props.algorithm}</p>
          </div>
        </div>
        <div className='w-full xl:w-1/2 lg:min-w-170px flex flex-row items-center justify-start mt-2'>
          <div className='w-8'>
            <FaIcon
              color={'text-blue-aqua'}
              iconName={'fas fa-shopping-bag'}
              size={'fa-lg'}></FaIcon>
          </div>
          <div className='w-auto flex flex-row items-end py-1'>
            <p className='mr-1 leading-none'>Mind. Bestellung:</p>
            <p className='font-universBoldCondensed leading-none'>{props.minimumOrder}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HardwareDetailProperties;
