import React, { useCallback, useContext, useEffect, useState } from 'react';
import { StoreContext } from '../../context/store-context';
import { isEqual } from 'lodash';
import HardwareDetailProperties from './hardwareDetailProperties';
import NumericInput from '../core/numericInput';
import AddToCartButton from './addToCartButton';
import { HardwareMetadata } from '../../lib/sanity-types/hardwareMetadata';

type HardwareFormProps = {
  product: any;
  hardwareMetadata: HardwareMetadata;
};

const HardwareForm = ({ product, hardwareMetadata }: HardwareFormProps) => {
  const {
    options,
    variants,
    variants: [initialVariant],
  } = product;

  const { client } = useContext(StoreContext);

  const [variant, setVariant] = useState({ ...initialVariant });
  const [quantity, setQuantity] = useState(1);

  const productVariant = client.product.helpers.variantForOptions(product, variant) || variant;

  const [available, setAvailable] = useState(productVariant.availableForSale);

  const checkAvailablity = useCallback(
    (productId) => {
      client.product.fetch(productId).then((fetchedProduct: any) => {
        const result =
          fetchedProduct?.variants.filter((variant: any) => variant.id === productVariant.storefrontId) ?? [];

        if (result.length > 0) {
          setAvailable(result[0].available);
        }
      });
    },
    [productVariant.storefrontId, client.product]
  );

  const handleOptionChange = (index: any, event: any) => {
    const value = event.target.value;

    if (value === '') {
      return;
    }

    const currentOptions = [...variant.selectedOptions];

    currentOptions[index] = {
      ...currentOptions[index],
      value,
    };

    const selectedVariant = variants.find((variant: any) => {
      return isEqual(currentOptions, variant.selectedOptions);
    });

    setVariant({ ...selectedVariant });
  };

  useEffect(() => {
    checkAvailablity(product.storefrontId);
  }, [productVariant.storefrontId, checkAvailablity, product.storefrontId]);

  const hasVariants = variants.length > 1;

  return (
    <div>
      <fieldset>
        {hasVariants &&
          options.map(({ id, name, values }: any, index: any) => (
            <div className="mt-4" key={index}>
              <select
                className="bg-blue-aqua text-white font-universBoldCondensed appearance-none border-none inline-block py-3 pl-4 pr-8 rounded leading-tight w-full lg:w-5/12"
                aria-label="Variants"
                onChange={(event) => handleOptionChange(index, event)}
                key={id}
              >
                <option value="">{`${name} auswählen`}</option>
                {values.map((value: any) => (
                  <option value={value} key={`${name}-${value}`}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          ))}
      </fieldset>
      <div className="mb-12">
        <HardwareDetailProperties
          hashrate={hardwareMetadata?.hashrate}
          shipping={hardwareMetadata?.shipping}
          powerConsumption={hardwareMetadata?.powerConsumption}
          condition={hardwareMetadata?.condition}
          algorithm={hardwareMetadata?.algorithm}
          minimumOrder={hardwareMetadata?.minimumOrder}
        ></HardwareDetailProperties>
      </div>
      <div className="flex flex-col lg:flex-row items-center">
        <div className="w-full h-full mb-4 lg:w-1/4 lg:mr-4 lg:mb-0">
          <NumericInput
            aria-label="Anzahl"
            onIncrement={() => setQuantity((q) => Math.min(q + 1, 20))}
            onDecrement={() => setQuantity((q) => Math.max(1, q - 1))}
            onChange={(event: any) => setQuantity(event.currentTarget.value)}
            value={quantity}
            available={available}
            min="1"
            max="20"
          ></NumericInput>
        </div>
        <div className="w-full h-full lg:w-3/4">
          <AddToCartButton
            variantId={productVariant.storefrontId}
            quantity={quantity}
            available={available}
            productTitle={product.title}
          ></AddToCartButton>
        </div>
      </div>
    </div>
  );
};

export default HardwareForm;
