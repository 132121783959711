import React from 'react';
import tw, { styled } from 'twin.macro';

type NumericInputProps = {
  onIncrement: any;
  onDecrement: any;
  available: boolean;
};

const StyledAddButton = styled.button`
  ${tw`bg-blue-aqua text-white hover:bg-blue-navy h-full w-20 rounded-r cursor-pointer outline-none disabled:opacity-50`}
  ${({ available }: { available: boolean }) => (available ? tw`hover:bg-blue-navy` : tw`cursor-not-allowed`)}
`;

const StyledMinusButton = styled.button`
  ${tw`bg-blue-aqua text-white h-full w-20 rounded-l cursor-pointer outline-none disabled:opacity-50`}
  ${({ available }: { available: boolean }) => (available ? tw`hover:bg-blue-navy` : tw` cursor-not-allowed`)}
`;

const NumericInput = ({ onIncrement, onDecrement, available, ...props }: NumericInputProps & any) => {
  return (
    <div className="flex flex-row h-full w-full rounded shadow font-universBoldCondensed bg-blue-aqua">
      <StyledMinusButton
        data-action="decrement"
        className="bg-blue-aqua text-white hover:bg-blue-navy h-full w-20 rounded-l cursor-pointer outline-none"
        disabled={!available}
        onClick={onDecrement}
        available={available}
      >
        <span className="m-auto text-xl">−</span>
      </StyledMinusButton>
      <input
        type="number"
        className="outline-none w-full text-center bg-blue-aqua text-white disabled:opacity-50"
        name="custom-input-number"
        disabled={!available}
        {...props}
      />
      <StyledAddButton
        data-action="increment"
        className="bg-blue-aqua text-white hover:bg-blue-navy h-full w-20 rounded-r cursor-pointer outline-none"
        disabled={!available}
        onClick={onIncrement}
        available={available}
      >
        <span className="m-auto text-xl">+</span>
      </StyledAddButton>
    </div>
  );
};

export default NumericInput;
