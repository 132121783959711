import React, { useContext } from 'react';
import { StoreContext } from '../../context/store-context';
import tw, { styled } from 'twin.macro';
import toast from 'react-hot-toast';

type AddToCartButtonProps = {
  variantId: string;
  quantity: number;
  available: boolean;
  productTitle: string;
};

const StyledButton = styled.button`
  ${tw`w-full py-3 px-6 leading-none font-universBoldCondensed text-center rounded shadow transition-colors duration-150 text-white bg-blue-aqua disabled:opacity-50`}
  ${({ available }: { available: boolean }) => (available ? tw`hover:bg-blue-navy` : tw`cursor-not-allowed`)}
`;

const AddToCartButton = ({ variantId, quantity, available, productTitle }: AddToCartButtonProps) => {
  const { addVariantToCart, loading } = useContext(StoreContext);

  const handleClick = (e: any) => {
    e.preventDefault();
    addVariantToCart(variantId, quantity);
    toast.success(`"${productTitle}" zum Warenkorb hinzugefügt!`, {
      duration: 4000,
      position: 'top-right',
      style: {
        minWidth: '250px',
        border: '1px solid #00F2EE',
        padding: '16px',
      },
    });
  };

  return (
    <StyledButton
      type="submit"
      className=""
      onClick={handleClick}
      disabled={!available || loading}
      available={available}
    >
      {available ? 'Zum Warenkorb hinzufügen' : 'Aktuell nicht verfügbar'}
    </StyledButton>
  );
};

export default AddToCartButton;
